import Button from '../../components/Button/Button';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import style from './Contacts.module.scss';
import { ContactsText } from './ContactsText';
import Input from '../../components/Input/Input';
import { ImgWebp } from '../../helpers/imgwebp';

import img from './assets/image.png';
import img2x from './assets/image@2x.png';
import imgWebp from './assets/image.webp';
import SectionDescription from '../../components/SectionDescription/SectionDescription';
import { useMediaQuery } from 'usehooks-ts';

const Contacts = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section id='contact' className={style.contacts}>
      <div className='container'>
        <div className={style.contactsBody}>
          <div className={style.contactsBody__content}>
            <SectionTitle className={style.contactsBody__title}>
              {ContactsText.title}
            </SectionTitle>
            <SectionDescription className={style.contactsBody__subtitle}>
              {ContactsText.subtitle}
            </SectionDescription>
            <div className={style.contactsBody__form}>
              {isMobile &&
                ContactsText.form.inputs.map((item, index) => (
                  <Input
                    key={index}
                    placeholder={item}
                    className={style.contactsBody__formInput}
                  />
                ))}

              {!isMobile && (
                <>
                  <div className={style.contactsBody__formRow}>
                    <label>
                      <span>My name is</span>
                      <Input placeholder='YOUR NAME' />
                    </label>
                  </div>
                  <div className={style.contactsBody__formRow}>
                    <label>
                      <span>Contact me</span>
                      <Input placeholder='EMAIL' />
                      <Input placeholder='PHONE' />
                    </label>
                  </div>
                  <div className={style.contactsBody__formRow}>
                    <label>
                      <span>I’m interested in</span>
                      <Input placeholder='QUESTION' />
                    </label>
                  </div>
                </>
              )}

              <Button className={style.contactsBody__formBtn}>Submit</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
